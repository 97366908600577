import React from "react";
import { Link } from "react-router";

const Header = () => (
  <header className="navigation">
    <div className="container">
      <nav className="navbar navbar-expand-lg navbar-light px-0">
        <a className="navbar-brand order-1 py-0" href="/">
          <img
            loading="prelaod"
            decoding="async"
            className="img-fluid"
            src={require('../assets/images/logo.png')}
            style={{width: '180px', height: '40px'}}
            alt="Growth Diary"
          />
        </a>
        <div className="navbar-actions order-3 ml-0 ml-md-4">
          <button
            aria-label="navbar toggler"
            className="navbar-toggler border-0"
            type="button"
            data-toggle="collapse"
            data-target="#navigation"
          >
            {" "}
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>
        <div
          className="collapse navbar-collapse order-lg-2 order-4"
          id="navigation"
        >
          <ul className="navbar-nav mx-auto mt-3 mt-lg-0">
            <li className="nav-item">
              {" "}
              <a className="nav-link" href="/">
                Home
              </a>
            </li>
            <li className="nav-item dropdown">
              {" "}
              <a
                className="nav-link dropdown-toggle"
                href="/category/make-money"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Articles
              </a>
              <div className="dropdown-menu">
                {" "}
                <a className="dropdown-item" href="/category/make-money">
                  Make Money Online
                </a>
                <a className="dropdown-item" href="/category/web-traffic">
                  Get More Traffic
                </a>
                <a className="dropdown-item" href="/category/entrepreneurship">
                  Business Advice
                </a>
                <a
                  className="dropdown-item"
                  href="/category/business-inspiration"
                >
                  Business Motivation
                </a>
              </div>
            </li>
            <li className="nav-item">
              {" "}
              <a className="nav-link" href="/about">
                About Us
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </header>
);

export default Header;
