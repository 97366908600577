import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import Header from "../components/header";
import Footer from "../components/footer";
import ReactMarkdown from "react-markdown";
import posts from "../database/blogs";
import RecommendedArticle from "../components/recommendedArticle";

const BlogPost = () => {
  const { id } = useParams();
  const [content, setContent] = useState("");
  const [post, setPost] = useState(posts.find((post) => post.id == id));

  useEffect(() => {
    import(`../database/posts/${id}.md`)
      .then((res) => fetch(res.default).then((res) => res.text()))
      .then((text) => setContent(text))
      .catch(() => setContent("Post not found."));
  }, [id]);

  return (
    <div>
      <meta name="og:title" content={post.title} />
      <meta name="og:description" content={post.subtitle} />
      <meta name="description" content={post.subtitle} />
      <meta name="og:url" content={`https://www.growthdiary.blog/${id}`} />
      <link rel="canonical" href={`https://www.growthdiary.blog/${id}`} />
      <title>{post.title}</title>
      <meta name="keywords" content={post.keywords} />
      <meta name="author" content="Team GrowthDiary"></meta>
      <meta name="og:type" content="article" />
      <meta name="robots" content="index, follow"></meta>
      <Header />
      <main>
        <section className="section">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 mb-5 mb-lg-0">
                <article>
                  <img
                    loading="lazy"
                    decoding="async"
                    src={post.image}
                    alt="Post Thumbnail"
                    className="w-100"
                  />
                  <ul className="post-meta mb-2 mt-4">
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        style={{ marginRight: "5px", marginTop: "-4px" }}
                        className="text-dark"
                        viewBox="0 0 16 16"
                      >
                        <path d="M5.5 10.5A.5.5 0 0 1 6 10h4a.5.5 0 0 1 0 1H6a.5.5 0 0 1-.5-.5z" />
                        <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H2z" />
                        <path d="M2.5 4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V4z" />
                      </svg>{" "}
                      <span>{post.date}</span>
                    </li>
                  </ul>

                  <ul className="post-meta mb-4">
                    <li>
                      {" "}
                      <a href={`/category/${post.category}`}>{post.category}</a>
                    </li>
                  </ul>
                  <div className="content text-left">
                    <ReactMarkdown>{content}</ReactMarkdown>
                  </div>
                </article>
              </div>
              <div className="col-lg-4">
                <div className="widget-blocks">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="widget">
                        <div className="widget-body">
                          <img
                            loading="lazy"
                            decoding="async"
                            src={require("../assets/images/author.webp")}
                            alt="About Me"
                            className="w-100 author-thumb-sm d-block"
                          />
                          <h2 className="widget-title my-3">Growth Diary</h2>
                          <p className="mb-3 pb-2">
                            "Growth Diary" reflects your dedication to
                            documenting, sharing, and inspiring growth in all
                            aspects of life. It’s a name that resonates with
                            readers eager to transform their ambitions into
                            achievements and create lasting impact.
                          </p>{" "}
                          <a
                            href="/about"
                            className="btn btn-sm btn-outline-primary"
                          >
                            Know More
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-6">
                      <div className="widget">
                        <h2 className="section-title mb-3">Recommended</h2>
                        <div className="widget-body">
                          <div className="widget-list">
                            <article className="card mb-4">
                              <div className="card-image">
                                <div className="post-info">
                                  {" "}
                                  <span className="text-uppercase">
                                    1 minutes read
                                  </span>
                                </div>
                                <img
                                  loading="lazy"
                                  decoding="async"
                                  src={posts[1].image}
                                  alt="Post Thumbnail"
                                  className="w-100"
                                />
                              </div>
                              <div className="card-body px-0 pb-1">
                                <h3>
                                  <a
                                    className="post-title post-title-sm"
                                    href={`/${posts[1].id}`}
                                  >
                                    {posts[1].title}
                                  </a>
                                </h3>
                                <p className="card-text">{posts[1].subtitle}</p>
                                <div className="content">
                                  {" "}
                                  <a
                                    className="read-more-btn"
                                    href={`/${posts[1].id}`}
                                  >
                                    Read Full Article
                                  </a>
                                </div>
                              </div>
                            </article>
                            {posts.slice(6, 8).map(function (post, index) {
                              return (
                                <RecommendedArticle post={post} key={index} />
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-6">
                      <div className="widget">
                        <h2 className="section-title mb-3">Categories</h2>
                        <div className="widget-body">
                          <ul className="widget-list">
                            <li>
                              <a href="/category/make-money">
                                make-money<span className="ml-auto"></span>
                              </a>
                            </li>
                            <li>
                              <a href="/category/web-traffic">
                                web-traffic<span className="ml-auto"></span>
                              </a>
                            </li>
                            <li>
                              <a href="/category/entrepreneurship">
                                entrepreneurship
                                <span className="ml-auto"></span>
                              </a>
                            </li>
                            <li>
                              <a href="category/business-inspiration">
                                business-inspiration
                                <span className="ml-auto"></span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default BlogPost;
