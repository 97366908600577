import React from "react";
import { Link } from "react-router";

const Article = ({post}) => (
    <article className="card article-card article-card-sm h-90">
    <Link to={`/${post.id}`}>
      <div className="card-image">
        <div className="post-info">
          {" "}
          <span className="text-uppercase">{post.date}</span>
          {/* <span className="text-uppercase">2 minutes read</span> */}
        </div>
        <img
          loading="lazy"
          decoding="async"
          src={post.image}
          alt="Post Thumbnail"
          className="w-100"
        />
      </div>
    </Link>
    <div className="card-body px-0 pb-0">
      <ul className="post-meta mb-2">
        <li>
          {" "}
          <a href={`/category/${post.category}`}>{post.category}</a>
        </li>
      </ul>
      <h2>
        <a className="post-title" href={`/${post.id}`}>
          {post.title}
        </a>
      </h2>
      <p className="card-text">
       {post.subtitle}
      </p>
      <div className="content">
        {" "}
        <a className="read-more-btn" href={`/${post.id}`}>
          Read Full Article
        </a>
      </div>
    </div>
  </article>
);

export default Article;
