import React from "react";

const Terms = () => (
  <main>
    <section className="section">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <div className="breadcrumbs mb-4">
              {" "}
              <a href="/">Home</a>
              <span className="mx-1">/</span> <a href="#!">Privacy policy</a>
            </div>
          </div>
          <div className="col-lg-8 mx-auto mb-5 mb-lg-0">
            <h1 className="mb-4">Our Privacy Policy</h1>
            <div className="content">
              <p>
                At Growth Diary, accessible at [yourwebsite.com], we are
                committed to protecting your privacy. This Privacy Policy
                outlines how we collect, use, and safeguard your information. By
                using our website, you agree to the terms of this Privacy
                Policy.
              </p>
              <h4 id="gathering-of-personal-information">
                Information We Collect
              </h4>

              <ol>
                We may collect the following types of information:
                <li>
                  Personal Information Name, email address, and other contact
                  details when voluntarily provided through forms (e.g.,
                  subscriptions or contact forms).
                </li>{" "}
                <li>
                  {" "}
                  Non-Personal Information Browsing behavior, device type,
                  browser type, and other analytical data through cookies and
                  similar technologies.
                </li>{" "}
                <li>
                  Cookies and Tracking Technologies Cookies, web beacons, and
                  similar tools are used to improve your browsing experience and
                  for analytics.
                </li>
              </ol>

              <h4 id="protection-of--personal--information">
                How We Use Your Information
              </h4>
              <ol>
                <p>We may use your information for:</p>
                <li style={{marginLeft: '40px'}}>Providing and improving our website.</li>
                <li style={{marginLeft: '40px'}}>Responding to your inquiries or comments.</li>{" "}
                <li style={{marginLeft: '40px'}}>
                  
                  Sending newsletters or promotional content, if you’ve
                  subscribed.{" "}
                </li>
                <li style={{marginLeft: '40px'}}>Ensuring compliance with legal obligations and terms.</li>
                <li style={{marginLeft: '40px'}}>Displaying relevant ads as part of Google AdSense.</li>
              </ol>

              <h4 id="privacy-policy-changes">
                Google AdSense and Third-Party Advertising
              </h4>
              <h5>a. Third-Party Cookies</h5>
              <ul>
                <li style={{marginLeft: '40px'}}>
                  Third-party vendors, including Google, use cookies to serve
                  ads based on your previous visits to our website or other
                  websites.
                </li>
                <li style={{marginLeft: '40px'}}>
                  Google’s use of the DoubleClick cookie enables it and its
                  partners to serve ads based on your visit to our site and/or
                  other sites on the internet.
                </li>
              </ul>
              <h5>b. Personalized Ads</h5>
              <ul>
                <li style={{marginLeft: '40px'}}>
                  Google AdSense enables personalized ads. You can opt out by
                  visiting Google Ads Settings.
                </li>
              </ul>
              <h5>c. Compliance with Policies</h5>
              <ul>
                <li style={{marginLeft: '40px'}}>
                  Growth Diary complies with Google AdSense policies, ensuring
                  ad placements are suitable, relevant, and non-intrusive.
                </li>
              </ul>
              <h4 id="protection-of--personal--information">
                How We Protect Your Information
              </h4>
              <p>
                We use industry-standard security measures, such as encryption
                and firewalls, to protect your data from unauthorized access,
                alteration, or disclosure. However, no method of transmission
                over the internet is 100% secure.
              </p>

              <h4 id="protection-of--personal--information">
                Sharing Your Information
              </h4>
              <p>
                We do not sell, trade, or rent your personal information to
                others. We may share information with:
              </p>
              <ul>
                <li style={{marginLeft: '40px'}}>
                  Service providers assisting in website operations (e.g.,
                  hosting or analytics).
                </li>
                <li style={{marginLeft: '40px'}}>Legal authorities, if required by law.</li>
              </ul>
              <h4 id="protection-of--personal--information">Your Rights</h4>
              <p>You have the right to:</p>
              <ol>
                <li style={{marginLeft: '40px'}}>
                  Access and review the personal information we collect about
                  you.
                </li>
                <li style={{marginLeft: '40px'}}>
                  Request correction or deletion of your personal information.
                </li>
                <li style={{marginLeft: '40px'}}>
                  Opt out of receiving promotional emails by clicking the
                  “unsubscribe” link.
                </li>
                <li style={{marginLeft: '40px'}}>
                  Manage cookies through your browser settings or third-party
                  opt-out tools.
                </li>
              </ol>
              <h4>External Links</h4>
              <p>
                Our site may contain links to external websites. We are not
                responsible for the content or privacy practices of these
                third-party sites.
              </p>
              <h4>Children’s Privacy</h4>
              <p>
                Growth Diary does not knowingly collect personal information
                from children under the age of 13. If you believe we have
                inadvertently collected such data, please contact us
                immediately.
              </p>
              <h4>Updates to this Privacy Policy</h4>
              <p>
                We may update this Privacy Policy periodically. Changes will be
                posted on this page with the updated effective date.
              </p>
              <h4>Contact Us</h4>
              <p>
                For questions about this Privacy Policy, please contact us at:
              </p>
              <ul>
                <li style={{marginLeft: '40px'}}>Email: info@growthdiary.com</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
);

export default Terms;
