import React from "react";
import { Link } from "react-router";

const RecommendedArticle = ({ post }) => (
  <a className="media align-items-center" href={`/${post.id}`}>
    <span class="image-fallback image-fallback-xs">{post.title}</span>
    <div className="media-body ml-3">
      <h3 style={{ marginTop: "-5px" }}>{post.title}</h3>
      <p className="mb-0 small">{post.subtitle}</p>
    </div>
  </a>
);

export default RecommendedArticle;
