import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import Header from "../components/header";
import Footer from "../components/footer";
import posts from "../database/blogs";
import Article from "../components/article";

const Blog = () => {
  const { category } = useParams();
  const [articles, setArticles] = useState([]);
  const [metaTitle, setMetaTitle] = useState(
    "How To Make Money Online - Ways To Monetize Your Website!"
  );
  const [description, setDescription] = useState("");
  const [ogTitle, setOgTitle] = useState("Make Money Online Archives");
  useEffect(() => {
    let articles = posts.filter((item) => item.category == category);
    setArticles(articles);
    if (category == "make-money") {
      setMetaTitle("How To Make Money Online - Ways To Monetize Your Website!");
      setOgTitle("Make Money Online Archives");
      setDescription(
        "Discover how to make money online through blogging. Our expert tips and strategies will help you monetize your blog and generate passive income."
      );
    } else if (category == "web-traffic") {
      setMetaTitle("Get Web Traffic Archives - How To Make Money Online");
      setOgTitle("Get Web Traffic Archives");
      setDescription("Learn effective strategies to drive website traffic and increase visibility for your blog. Get expert tips and insights from our comprehensive guide.")
    } else if (category == "entrepreneurship") {
      setMetaTitle("Entrepreneurship Archives - How To Make Money Online");
      setOgTitle("Entrepreneurship Archives");
      setDescription("Discover valuable articles for entrepreneurs looking to make money online. Get expert insights and tips to help you succeed in your online business.")
    } else if (category == "business-inspiration") {
      setMetaTitle("Business Motivation Archives - How To Make Money Online");
      setOgTitle("Business Motivation Archives");
      setDescription("Discover the best business inspiration to fuel your success. Get inspired and motivated with our collection of insightful articles and stories.")
    }
  }, [category]);
  return (
    <div>
      <meta name="og:title" content={ogTitle} />
      <title>{metaTitle}</title>
      <meta name="og:description" content={description} />
      <meta name="description" content={description} />
      <meta name="og:url" content={`https://www.growthdiary.blog/category/${category}`} />
      <link rel="canonical" href={`https://www.growthdiary.blog/category/${category}`} />
      <meta name="og:type" content="article" />
      <Header />
      <section className="section">
        <div className="row no-gutters-lg">
          {articles.length > 0 &&
            articles.map(function (post, index) {
              return (
                <div key={index} className="col-md-3 mb-3">
                  <Article post={post} />
                </div>
              );
            })}
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Blog;
