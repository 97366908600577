import React, { useEffect, useState } from "react";
import { Link } from "react-router";
import Header from "../components/header";
import Footer from "../components/footer";
import Article from "../components/article";
import RecommendedArticle from "../components/recommendedArticle";
import posts from "../database/blogs";
import { logEvent } from "firebase/analytics";
import { analytics } from "../firebase";
const Home = () => {
  useEffect(() => {
    logEvent(analytics, "custom_event", {
      page: "HomePage",
      description: "User visited the homepage",
    });
  }, []);

  return (
    <div>
      <meta name="og:title" content="Home" />
      <meta
        name="og:description"
        content="Learn about blogging, eCommerce, entrepreneurship, SEO, content marketing and being successful in life from a how to make money online pioneer"
      />
      <meta
        name="description"
        content="Learn about blogging, eCommerce, entrepreneurship, SEO, content marketing and being successful in life from a how to make money online pioneer"
      />
      <meta name="og:url" content="https://www.growthdiary.blog/" />
      <link rel="canonical" href="https://www.growthdiary.blog/" />
      <title>How To Make Money Online - Get Started With GrowthDiary</title>
      <meta name="og:type" content="website" />
      <Header />
      <main>
        <section className="section">
          <div className="container">
            <div className="row no-gutters-lg">
              <div className="col-12">
                <h2 className="section-title">Latest Articles</h2>
              </div>
              <div className="col-lg-8 mb-5 mb-lg-0">
                <div className="row">
                  <div className="col-12 mb-4">
                    <article className="card article-card">
                      <a href={`/${posts[0].id}`}>
                        <div className="card-image">
                          <div className="post-info">
                            {" "}
                            <span className="text-uppercase">
                              {posts[0].date}
                            </span>
                            {/* <span className="text-uppercase">
                              4 minutes read
                            </span> */}
                          </div>
                          <img
                            loading="lazy"
                            decoding="async"
                            src={posts[0].image}
                            alt="Post Thumbnail"
                            className="w-100"
                          />
                        </div>
                      </a>
                      <div className="card-body px-0 pb-1">
                        <ul className="post-meta mb-2">
                          <li>
                            {" "}
                            <a href={`/category/${posts[0].category}`}>
                              {posts[0].category}
                            </a>
                          </li>
                        </ul>
                        <h2 className="h1">
                          <a className="post-title" href={`/${posts[0].id}`}>
                            {posts[0].title}
                          </a>
                        </h2>
                        <p className="card-text">{posts[0].subtitle}</p>
                        <div className="content">
                          {" "}
                          <a className="read-more-btn" href={`/${posts[0].id}`}>
                            Read Full Article
                          </a>
                        </div>
                      </div>
                    </article>
                  </div>
                  {posts.slice(2, 6).map(function (post, index) {
                    return (
                      <div key={index} className="col-md-6 mb-4">
                        <Article post={post} />
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="col-lg-4">
                <div className="widget-blocks">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="widget">
                        <div className="widget-body">
                          <img
                            loading="lazy"
                            decoding="async"
                            src={require("../assets/images/gd-m1.webp")}
                            alt="About Me"
                            className="w-100 author-thumb-sm d-block"
                          />
                          <h2 className="widget-title my-3">Growth Diary</h2>
                          <p className="mb-3 pb-2">
                            It is your ultimate companion on the journey to
                            personal, professional, and financial success. It’s
                            more than just a blog—it's a resource hub for
                            dreamers, doers, and go-getters who are eager to
                            elevate their lives through knowledge, inspiration,
                            and actionable strategies.
                          </p>{" "}
                          <a
                            href="/about"
                            className="btn btn-sm btn-outline-primary"
                          >
                            Know More
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-6">
                      <div className="widget">
                        <h2 className="section-title mb-3">Recommended</h2>
                        <div className="widget-body">
                          <div className="widget-list">
                            <article className="card mb-4">
                              <div className="card-image">
                                <div className="post-info">
                                  {" "}
                                  {/* <span className="text-uppercase">
                                    4 minutes read
                                  </span> */}
                                </div>
                                <img
                                  loading="lazy"
                                  decoding="async"
                                  src={posts[1].image}
                                  alt="Post Thumbnail"
                                  className="w-100"
                                />
                              </div>
                              <div className="card-body px-0 pb-1">
                                <h3>
                                  <a
                                    className="post-title post-title-sm"
                                    href={`/${posts[1].id}`}
                                  >
                                    {posts[1].title}
                                  </a>
                                </h3>
                                <p className="card-text">{posts[1].subtitle}</p>
                                <div className="content">
                                  {" "}
                                  <a
                                    className="read-more-btn"
                                    href={`/${posts[1].id}`}
                                  >
                                    Read Full Article
                                  </a>
                                </div>
                              </div>
                            </article>
                            {posts.slice(6, 8).map(function (post, index) {
                              return (
                                <RecommendedArticle post={post} key={index} />
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-6">
                      <div className="widget">
                        <h2 className="section-title mb-3">Categories</h2>
                        <div className="widget-body">
                          <ul className="widget-list">
                            <li>
                              <a href="/category/make-money">
                                make-money<span className="ml-auto"></span>
                              </a>
                            </li>
                            <li>
                              <a href="/category/web-traffic">
                                web-traffic<span className="ml-auto"></span>
                              </a>
                            </li>
                            <li>
                              <a href="/category/entrepreneurship">
                                entrepreneurship
                                <span className="ml-auto"></span>
                              </a>
                            </li>
                            <li>
                              <a href="category/business-inspiration">
                                business-inspiration
                                <span className="ml-auto"></span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default Home;
