import React from "react";
import { BrowserRouter, Routes, Route } from "react-router";
import Home from "./pages/home";
import About from "./pages/about";
import Terms from "./pages/terms";
import BlogPost from "./pages/blogPost";
import Blog from "./pages/blog";
import Privacy from "./pages/privacy";

const App = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/privacy-policy" element={<Privacy />} />
      <Route path="/:id" element={<BlogPost />} />
      <Route path="/category/:category" element={<Blog />} />
    </Routes>
  </BrowserRouter>
);

export default App;
